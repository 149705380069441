<template>
  <div id="application_status">
    <div class="status">
      <div class="number">
        <p>1</p>
        <span></span>
        <p>2</p>
        <span style="border-color: #999999"></span>
        <p style="border-color: #999999; color: #999999">3</p>
      </div>
      <div class="text">
        <span>提交申请</span>
        <span>资料审核</span>
        <span style="color: #999999">团长开业</span>
      </div>
    </div>
    <div class="content">
      <h4>团长资料状态：审核中</h4>
      <p>正品汇平台审核通过后，可以正式开业；</p>
      <h4 style="text-align: start">
        团长需开通平台合作银行的资金账户，开通后系统将开放团长业务所需的全部功能，请立即开通。
      </h4>
      <button @click="toOpen">立即开通</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "application-status",

  data() {
    return {};
  },
//code=051z1iGa15LRVD0cpDHa1ulnqg1z1iG0

  mounted() {},

  methods: {
    toOpen() {
      this.$router.replace({
        path: "capitalAccount",
        query: {
          PDA: this.$route.query.PDA,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#application_status {
  background-color: #f5f5f5;
  height: 100%;
  padding: 0 15px;
  .status {
    padding: 40px 0;
    .number {
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        width: 22px;
        height: 22px;
        border: 1px solid #009a62;
        border-radius: 50%;
        color: #009a62;
        text-align: center;
        line-height: 20px;
      }
      span {
        width: 80px;
        height: 0px;
        border-top: 1px solid #009a4d;
      }
    }
    .text {
      display: flex;
      justify-content: center;
      margin-top: 12px;
      font-weight: bold;
      color: #333333;
      span:nth-child(2) {
        padding: 0 47px;
      }
    }
  }
  .content {
    background: #fff;
    padding: 18px 24px;
    border-radius: 5px;
    text-align: center;
    h4 {
      font-size: 16px;
      line-height: 20px;
      color: #333333;
    }
    P {
      color: #666666;
      margin: 6px 0 40px;
    }
    button {
      margin-top: 24px;
      border: none;
      color: #fff;
      background: #009a4d;
      border-radius: 25px;
      padding: 8px 30px;
    }
  }
}
</style>